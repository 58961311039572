import '../types';
import { AssignmentQuestionAnswer } from './AssignmentQuestionAnswer';
class AssignmentQuestion {
    constructor(questionIndex, question) {
        this.questionIndex = questionIndex;
        this.question = question;
        this.answers = this.initAnswers(question);
        this.selectedAnswerIndex = null;
    }
    initAnswers(question) {
        let answers = [];
        for (let i = 0; i < question.answers.length; i++) {
            let assignmentAnswer = new AssignmentQuestionAnswer(i, this.question.answers[i]);
            answers.push(assignmentAnswer);
        }
        return answers;
    }
    setSelectedAnswer(selectedAnswerIndex) {
        this.selectedAnswerIndex = selectedAnswerIndex;
    }
    getComponentFormat(submitted) {
        let answers = [];
        for (let i = 0; i < this.answers.length; i++) {
            answers.push(this.answers[i].getComponentFormat(this.selectedAnswerIndex, submitted));
        }
        return {
            id: this.question.id,
            assignmentIndex: this.questionIndex,
            body: this.question.body,
            bodyFormat: this.question.bodyFormat,
            answers: answers
        };
    }
    isCorrect() {
        if (this.selectedAnswerIndex != null) {
            return this.question.answers[this.selectedAnswerIndex].correct;
        }
        return false;
    }
}
export { AssignmentQuestion };
