import '../types';
class AssignmentQuestionAnswer {
    constructor(answerIndex, answer) {
        this.answerIndex = answerIndex;
        this.answer = answer;
    }
    getComponentFormat(selectedQuestionAnswerIndex, submitted) {
        return {
            letter: this.answer.letter,
            body: this.answer.body,
            bodyForamt: this.answer.bodyFormat,
            assignmentIndex: this.answerIndex,
            state: this.getState(selectedQuestionAnswerIndex, submitted)
        };
    }
    getState(selectedQuestionAnswerIndex, submitted) {
        if (submitted &&
            this.answer.correct &&
            this.isSelected(selectedQuestionAnswerIndex)) {
            return 'correct';
        }
        else if (submitted &&
            this.answer.correct &&
            !this.isSelected(selectedQuestionAnswerIndex)) {
            return 'solution';
        }
        else if (submitted &&
            !this.answer.correct &&
            this.isSelected(selectedQuestionAnswerIndex)) {
            return 'incorrect';
        }
        else if (this.isSelected(selectedQuestionAnswerIndex)) {
            return 'selected';
        }
        return 'unselected';
    }
    isSelected(selectedQuestionAnswerIndex) {
        return selectedQuestionAnswerIndex === this.answerIndex;
    }
    isCorrect(selectedQuestionAnswerIndex) {
        return this.answer.correct && this.isSelected(selectedQuestionAnswerIndex);
    }
}
export { AssignmentQuestionAnswer };
