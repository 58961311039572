import '../types';
import { AssignmentQuestion } from './AssignmentQuestion';
import 'svelte/internal';
class Assignment {
    constructor(assignment) {
        this.input = assignment;
        this.state = this.initState(assignment);
        this.subscribers = [];
        this.submitted = false;
    }
    // initializes an AssignmentQuestion for each question present on the input
    initState(assignment) {
        let state = [];
        for (let i = 0; i < assignment.length; i++) {
            let assignmentQuestion = new AssignmentQuestion(i, this.input[i]);
            state.push(assignmentQuestion);
        }
        return state;
    }
    // subscribe is required for svelte stores
    subscribe(subscriber) {
        subscriber(this);
        this.subscribers.push(subscriber);
        return () => { };
    }
    // setter for updating submitted
    setSubmitted(submitted) {
        this.submitted = submitted;
    }
    // notify subscribers of state change
    notify() {
        this.subscribers.forEach(subscriber => {
            subscriber(this);
        });
    }
    submit() {
        this.setSubmitted(true);
        this.notify();
    }
    // returns the object that should be based to the ui components
    getComponentFormat() {
        let questions = [];
        for (let i = 0; i < this.input.length; i++) {
            questions.push(this.state[i].getComponentFormat(this.submitted));
        }
        return {
            questions: questions
        };
    }
    setSelectedAnswer(questionIndex, selectedAnswerIndex) {
        this.state[questionIndex].setSelectedAnswer(selectedAnswerIndex);
        this.notify();
    }
    getScore() {
        let score = 0;
        this.state.forEach(question => {
            if (question.isCorrect()) {
                score += 1;
            }
        });
        return score;
    }
    getMaxScore() {
        return this.state.length;
    }
    getDisplayScore() {
        return this.getScore() + '/' + this.state.length;
    }
    isPassingScore() {
        return this.getScore() === this.state.length;
    }
}
export { Assignment };
